<template>
    <div class="box">
        <Header type="expert" :logo="logoUrl" :navList="navList" :typeList="[]" :navCurrentId="navCurrentId">
            <div slot="search" style="display: none"></div>
        </Header>

        <div class="content">
            <div class="person-item flex x-left" v-for="(item,index) in pageData" :key="index">
                <div class="user-info flex x-center y-center">
                    {{ item.name }}
                </div>
                <!--  简历  -->
                <div class="resume flex-item t-l">
                    <a-tabs>
                        <a-tab-pane key="1" :tab="data.name" v-for="(data,idx) in item.list" :key="idx">
                            <div class="resume-items">
                                <div class="resume-item flex x-left" v-for="(list,i) in data.list" :key="i" @click="handleToNext(list)">
                                    <div class="time">
                                        <span v-if="list.BeginTime && list.EndTime">{{ list.BeginTime | formatDate('yyyy年') }} - {{ list.EndTime | formatDate('yyyy年') }}</span>
                                    </div>
                                    <div class="surname">
                                        {{ list.full_name }}
                                    </div>
                                </div>
                            </div>
                        </a-tab-pane>
                    </a-tabs>
                </div>
            </div>
        </div>


        <Footer></Footer>
    </div>
</template>

<script>
import {exportLogo , expertNavList } from "@/config/const";
import Header from '@/components/layout/layout-header';
import Footer from "@/components/layout/layout-footer.vue";
export default {
    name: "branchVenueHome",
    components : {
        Header , Footer
    },
    data(){
        return {
            logoUrl: exportLogo,
            navList: expertNavList,
            navCurrentId: 5,//当前页面菜单对应下标
            pageData : []
        }
    },
    created() {
      this.queryData = this.$route.query;
      this.getDataList();
    },
    mounted() {
        let headerHeight = document.querySelector('.header').offsetHeight;
        let footerHeight = document.querySelector('.footer').offsetHeight;
        let countHeight = parseInt(footerHeight) + parseInt(headerHeight) + 56;
        document.querySelector('.content').style.minHeight = `calc(100vh - ${countHeight}px)`;
    },
    methods : {
        getDataList(){
            this.request.post('RegionBranchDetails',{subject_id : this.queryData.id}).then(res => {
                this.pageData = res.data.data;
            })
        },
        handleToNext(data){
            let { CouncilID , branch_id : BranchId , PeriodID, name } = data;
            this.$router.push({
                path : '/job-warehouse/rollCall',
                query : {
                    CouncilID,
                    BranchId,
                    PeriodID,
                    name
                }
            })
        }
    }
}
</script>

<style scoped lang="less">
.content{
    width: 1240px;
    margin: 0 auto;
    .person-item{
        width: 100%;
        height: 220px;
        background: #FFFFFF;
        box-shadow: 0px 1px 6px 0px rgba(0,0,0,0.1);
        border-radius: 10px;
        overflow: hidden;
        margin-bottom: 30px;
        .user-info{
            flex-shrink: 0;
            height: 100%;
            width: 153px;
            background: #1E51C9;
            color: #FFFFFF;
            font-weight: bold;
            font-size: 22px;
        }
        .resume{
            //width: calc(100% - 153px);
            .resume-items{
                height: 158px;
                overflow-y: auto;
                .resume-item{
                    padding-left: 30px;
                    margin-bottom: 18px;
                    cursor: pointer;
                    & > div{
                        margin-right: 50px;
                    }
                    .time{
                        min-width: 100px;
                    }
                    .surname{
                        min-width: 300px;
                    }
                }
            }
        }
    }
}
/deep/ .ant-tabs-nav .ant-tabs-tab-active{
    color: #1E51C9;
    font-weight: bold;
}
/deep/ .ant-tabs-ink-bar{
    background: #1E51C9;
}
/deep/ .ant-tabs-nav .ant-tabs-tab:first-child{
    margin-left: 30px;
}
</style>