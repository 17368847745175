import { render, staticRenderFns } from "./branchVenueHome.vue?vue&type=template&id=115a5552&scoped=true&"
import script from "./branchVenueHome.vue?vue&type=script&lang=js&"
export * from "./branchVenueHome.vue?vue&type=script&lang=js&"
import style0 from "./branchVenueHome.vue?vue&type=style&index=0&id=115a5552&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "115a5552",
  null
  
)

export default component.exports